import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './ascension.scss';
import { Table } from 'react-bootstrap';

const EsGuidesMonthlyPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ascension-guide'} game="es">
      <ul className="breadcrumb">
        <li>
          <Link to="/eversoul/">Eversoul</Link>
        </li>
        <li className="divider">/</li>
        <li>Monthly resource income (F2P)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/eversoul/categories/category_income.png"
            alt="Monthly resource income (F2P)"
          />
        </div>
        <div className="page-details">
          <h1>Monthly resource income (F2P)</h1>
          <h2>
            Learn how much tickets, Everstone and other resources you can earn
            every month as a F2P player.
          </h2>
          <p>
            Last updated: <strong>17/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Intro" />
        <p>
          Eversoul provides a lot of free resources to F2P players every month
          and we calculated every source of them to provide you with a list of
          tickets, Everstone and other items you can earn every month.
        </p>
        <SectionHeader title="Summon Tickets" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Summon Tickets</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Attendance </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 1.50 </td>
              <td align="right"> 22.50 </td>
              <td align="right"> 22.50 </td>
              <td align="right"> 22.50 </td>
            </tr>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 24.00 </td>
              <td align="right"> 28.00 </td>
            </tr>
            <tr>
              <td> Dailies </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
            </tr>
            <tr>
              <td> Weeklies </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
            </tr>
            <tr>
              <td> Daily Free Summon </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
            </tr>
            <tr>
              <td> Event Login </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
            </tr>
            <tr>
              <td> Event Story Clear </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
            </tr>
            <tr>
              <td> Event Story Clear (Rerun) </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
              <td align="right"> 7.00 </td>
            </tr>
            <tr>
              <td> Gachapon </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 8.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 8.00 </td>
              <td align="right"> 12.00 </td>
            </tr>
            <tr>
              <td> Season Pass Free </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 189.50 </td>
              <td align="right"> 203.50 </td>
              <td align="right"> 211.50 </td>
            </tr>
            <tr>
              <td> Season Pass (Paid - 22$) (Normal Tickets) </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
            </tr>
            <tr>
              <td> Season Pass (Paid - 22$) (Pick Up Tickets) </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 15.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 219.50 </td>
              <td align="right"> 233.50 </td>
              <td align="right"> 241.50 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Type Summon" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Type Summon</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 2.00 </td>
              <td align="right"> 2.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 8.00 </td>
              <td align="right"> 8.00 </td>
            </tr>
            <tr>
              <td> Attendance </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
            </tr>
            <tr>
              <td> Gachapon </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 6.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 12.00 </td>
              <td align="right"> 17.00 </td>
              <td align="right"> 19.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Artifact Summons" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Artifact Summon</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Attendance </td>
              <td> 5.00 </td>
              <td> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 1.00 </td>
              <td> 5.00 </td>
              <td> 5.00 </td>
              <td align="right"> 5.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions </td>
              <td> 15.00 </td>
              <td> 15.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 1.50 </td>
              <td> 22.50 </td>
              <td> 22.50 </td>
              <td align="right"> 22.50 </td>
            </tr>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td> 3.00 </td>
              <td> 8.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 4.00 </td>
              <td> 12.00 </td>
              <td> 32.00 </td>
              <td align="right"> 40.00 </td>
            </tr>
            <tr>
              <td> Weeklies </td>
              <td> 3.00 </td>
              <td> 3.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 4.00 </td>
              <td> 12.00 </td>
              <td> 12.00 </td>
              <td align="right"> 12.00 </td>
            </tr>
            <tr>
              <td> Gachapon </td>
              <td> 12.00 </td>
              <td> 16.00 </td>
              <td align="right"> 24.00 </td>
              <td align="right"> 1.00 </td>
              <td> 12.00 </td>
              <td> 16.00 </td>
              <td align="right"> 24.00 </td>
            </tr>
            <tr>
              <td> Gachapon (Box 9) </td>
              <td> - </td>
              <td> - </td>
              <td align="right"> 40.00 </td>
              <td align="right"> 1.00 </td>
              <td> - </td>
              <td> - </td>
              <td align="right"> 40.00 </td>
            </tr>
            <tr>
              <td> Season Pass Free </td>
              <td> 10.00 </td>
              <td> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 1.00 </td>
              <td> 10.00 </td>
              <td> 10.00 </td>
              <td align="right"> 10.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 73.50 </td>
              <td> 97.50 </td>
              <td align="right"> 153.50 </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Gachapon Box 9 assumes you cleared Box 9 for 40 Artifact Tickets
          reward, Luck/spending can increase this exponentially.
        </p>
        <SectionHeader title="Friendship Points" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Friendship Points</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Receiving Hearts </td>
              <td> 20.00 </td>
              <td align="right"> 20.00 </td>
              <td align="right"> 20.00 </td>
              <td align="right"> 30.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
            </tr>
            <tr>
              <td> Daily Town Visits Done </td>
              <td> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td> 900.00 </td>
              <td> 900.00 </td>
              <td> 900.00 </td>
            </tr>
            <tr>
              <td> Daily Town Visited </td>
              <td> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td> 900.00 </td>
              <td> 900.00 </td>
              <td> 900.00 </td>
            </tr>
            <tr>
              <td> Daily Souls Hired </td>
              <td> - </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 20.00 </td>
              <td align="right"> 30.00 </td>
              <td> - </td>
              <td> 150.00 </td>
              <td> 600.00 </td>
            </tr>
            <tr>
              <td> Soul Birthdays </td>
              <td> 100.00 </td>
              <td align="right"> 200.00 </td>
              <td align="right"> 200.00 </td>
              <td align="right"> 1.00 </td>
              <td> 100.00 </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td> </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 2,500.00 </td>
              <td> 2,750.00 </td>
              <td> 3,200.00 </td>
            </tr>
            <tr>
              <td> Monthly Sub (4$) </td>
              <td> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 30.00 </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td> </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 300.00 </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 2,800.00 </td>
              <td> 3,050.00 </td>
              <td> 3,500.00 </td>
            </tr>
            <tr>
              <td> Pulls </td>
              <td> </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 280.00 </td>
              <td> 305.00 </td>
              <td> 350.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Rare shards" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Rare Shard</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Attendance </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 1.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 1.50 </td>
              <td> 270.00 </td>
              <td> 270.00 </td>
              <td> 270.00 </td>
            </tr>
            <tr>
              <td> Dailies </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 30.00 </td>
              <td> 150.00 </td>
              <td> 150.00 </td>
              <td> 150.00 </td>
            </tr>
            <tr>
              <td> Weeklies </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 4.00 </td>
              <td> 240.00 </td>
              <td> 240.00 </td>
              <td> 240.00 </td>
            </tr>
            <tr>
              <td> Weekly Free Shop Pack </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 4.00 </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
            </tr>
            <tr>
              <td> Monthly Free Shop Pack </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
            </tr>
            <tr>
              <td> Labyrinth (Hero) </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 15.00 </td>
              <td> 750.00 </td>
              <td> 750.00 </td>
              <td> 750.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 1,950.00 </td>
              <td> 1,950.00 </td>
              <td> 1,950.00 </td>
            </tr>
            <tr>
              <td> Characters </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 32.50 </td>
              <td> 32.50 </td>
              <td> 32.50 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Epic shards" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Epic Shard</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Attendance </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.50 </td>
              <td align="right"> 90.00 </td>
              <td align="right"> 90.00 </td>
              <td align="right"> 90.00 </td>
            </tr>
            <tr>
              <td> Weeklies </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 40.00 </td>
              <td align="right"> 40.00 </td>
              <td align="right"> 40.00 </td>
            </tr>
            <tr>
              <td> Daily Free Shop Pack </td>
              <td align="right"> 2.00 </td>
              <td align="right"> 2.00 </td>
              <td align="right"> 2.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
            </tr>
            <tr>
              <td> Weekly Free Shop Pack </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 5.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 20.00 </td>
              <td align="right"> 20.00 </td>
              <td align="right"> 20.00 </td>
            </tr>
            <tr>
              <td> Monthly Free Shop Pack </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
            </tr>
            <tr>
              <td> Labyrinth (Hero) </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 15.00 </td>
              <td align="right"> 150.00 </td>
              <td align="right"> 150.00 </td>
              <td align="right"> 150.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 430.00 </td>
              <td align="right"> 430.00 </td>
              <td align="right"> 430.00 </td>
            </tr>
            <tr>
              <td> Season Pass (Paid - 22$) (Shards) </td>
              <td align="right"> 120.00 </td>
              <td align="right"> 120.00 </td>
              <td align="right"> 120.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 120.00 </td>
              <td align="right"> 120.00 </td>
              <td align="right"> 120.00 </td>
            </tr>
            <tr>
              <td> Season Pass (Paid - 22$) (Faction Selector) </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 610.00 </td>
              <td align="right"> 610.00 </td>
              <td align="right"> 610.00 </td>
            </tr>
            <tr>
              <td> Characters - </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"> 10.17 </td>
              <td align="right"> 10.17 </td>
              <td align="right"> 10.17 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Everstones" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Everstones</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Attendance </td>
              <td> 1,600.00 </td>
              <td> 1,600.00 </td>
              <td> 1,600.00 </td>
              <td align="right"> 1.00 </td>
              <td> 1,600.00 </td>
              <td> 1,600.00 </td>
              <td> 1,600.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Ranking </td>
              <td> 1,000.00 </td>
              <td> 1,200.00 </td>
              <td> 2,200.00 </td>
              <td align="right"> 1.50 </td>
              <td> 1,500.00 </td>
              <td> 1,800.00 </td>
              <td> 3,300.00 </td>
            </tr>
            <tr>
              <td> Arena Weekly Ranking (2000/1000/10) </td>
              <td> 100.00 </td>
              <td> 100.00 </td>
              <td> 200.00 </td>
              <td align="right"> 4.00 </td>
              <td> 400.00 </td>
              <td> 400.00 </td>
              <td> 800.00 </td>
            </tr>
            <tr>
              <td> C.Arena Daily (2000/1000/10) </td>
              <td> 24.00 </td>
              <td> 24.00 </td>
              <td> 96.00 </td>
              <td align="right"> 30.00 </td>
              <td> 720.00 </td>
              <td> 720.00 </td>
              <td> 2,880.00 </td>
            </tr>
            <tr>
              <td> Evil Soul Subjugation (5000/2500/100) </td>
              <td> 1,000.00 </td>
              <td> 1,000.00 </td>
              <td> 1,500.00 </td>
              <td align="right"> 2.00 </td>
              <td> 2,000.00 </td>
              <td> 2,000.00 </td>
              <td> 3,000.00 </td>
            </tr>
            <tr>
              <td> Dailies </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
              <td align="right"> 30.00 </td>
              <td> 1,500.00 </td>
              <td> 1,500.00 </td>
              <td> 1,500.00 </td>
            </tr>
            <tr>
              <td> Weeklies </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
              <td align="right"> 4.00 </td>
              <td> 1,200.00 </td>
              <td> 1,200.00 </td>
              <td> 1,200.00 </td>
            </tr>
            <tr>
              <td> Daily Free Shop Pack </td>
              <td> 20.00 </td>
              <td> 20.00 </td>
              <td> 20.00 </td>
              <td align="right"> 30.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Free) </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td align="right"> 1.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
            </tr>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td> 550.00 </td>
              <td> 970.00 </td>
              <td> 1,030.00 </td>
              <td align="right"> 4.00 </td>
              <td> 2,200.00 </td>
              <td> 3,880.00 </td>
              <td> 4,120.00 </td>
            </tr>
            <tr>
              <td> Eden Alliance (100%/50%/1%) </td>
              <td> 350.00 </td>
              <td> 630.00 </td>
              <td> 1,750.00 </td>
              <td align="right"> 1.00 </td>
              <td> 350.00 </td>
              <td> 630.00 </td>
              <td> 1,750.00 </td>
            </tr>
            <tr>
              <td> Maintenance </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
              <td> 300.00 </td>
              <td align="right"> 4.00 </td>
              <td> 1,200.00 </td>
              <td> 1,200.00 </td>
              <td> 1,200.00 </td>
            </tr>
            <tr>
              <td> Labyrinth (Normal/Elite/Hero) </td>
              <td> 210.00 </td>
              <td> 250.00 </td>
              <td> 300.00 </td>
              <td align="right"> 15.00 </td>
              <td> 3,150.00 </td>
              <td> 3,750.00 </td>
              <td> 4,500.00 </td>
            </tr>
            <tr>
              <td> Event Login </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
              <td align="right"> 12.00 </td>
              <td> 2,400.00 </td>
              <td> 2,400.00 </td>
              <td> 2,400.00 </td>
            </tr>
            <tr>
              <td> Event Raid Max Damage Rewards </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td align="right"> 1.00 </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
            </tr>
            <tr>
              <td> Event Stages First Clear Rewards </td>
              <td> 2,600.00 </td>
              <td> 2,600.00 </td>
              <td> 2,600.00 </td>
              <td align="right"> 1.00 </td>
              <td> 2,600.00 </td>
              <td> 2,600.00 </td>
              <td> 2,600.00 </td>
            </tr>
            <tr>
              <td> Event Login (Rerun) </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
              <td align="right"> 6.00 </td>
              <td> 1,200.00 </td>
              <td> 1,200.00 </td>
              <td> 1,200.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 26,220.00 </td>
              <td> 29,080.00 </td>
              <td> 36,250.00 </td>
            </tr>
            <tr>
              <td> Monthly Sub (4$) </td>
              <td> 3,300.00 </td>
              <td> 3,300.00 </td>
              <td> 3,300.00 </td>
              <td align="right"> 1.00 </td>
              <td> 3,300.00 </td>
              <td> 3,300.00 </td>
              <td> 3,300.00 </td>
            </tr>
            <tr>
              <td> Monthly Sub (13$) </td>
              <td> 9,950.00 </td>
              <td> 9,950.00 </td>
              <td> 9,950.00 </td>
              <td align="right"> 1.00 </td>
              <td> 9,950.00 </td>
              <td> 9,950.00 </td>
              <td> 9,950.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td align="right"> 1.00 </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
            </tr>
            <tr>
              <td> Season Pass (Paid - 22$) </td>
              <td> 2,500.00 </td>
              <td> 2,500.00 </td>
              <td> 2,500.00 </td>
              <td align="right"> 1.00 </td>
              <td> 2,500.00 </td>
              <td> 2,500.00 </td>
              <td> 2,500.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 18,750.00 </td>
              <td> 18,750.00 </td>
              <td> 18,750.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 44,970.00 </td>
              <td> 47,830.00 </td>
              <td> 55,000.00 </td>
            </tr>
            <tr>
              <td> Pulls </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 214.14 </td>
              <td> 227.76 </td>
              <td> 261.90 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Arena Coins" />
        <p>
          Daily Match Victory Assumption - 6 Wins (5 Free + 1 Ticket from
          Dailies), Income can increase by 15-30k a month if ranked high (sub
          500) Average of .7-1.5 Ayames per month depending on ranking and 2x
          Coin events.
        </p>
        <p>
          C. Arena Calculation - 110/140/210 coins per hour at those ranks
          claimed every 24 hours.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Arena Coins</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Arena Daily Ranking (2000/1000/10) </td>
              <td> 800.00 </td>
              <td> 1,000.00 </td>
              <td> 2,000.00 </td>
              <td align="right"> 30.00 </td>
              <td> 24,000.00 </td>
              <td> 30,000.00 </td>
              <td> 60,000.00 </td>
            </tr>
            <tr>
              <td> Arena Daily Victory </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
              <td> 200.00 </td>
              <td align="right"> 180.00 </td>
              <td> 36,000.00 </td>
              <td> 36,000.00 </td>
              <td> 36,000.00 </td>
            </tr>
            <tr>
              <td> Arena Weekly Ranking (2000/1000/10) </td>
              <td> 900.00 </td>
              <td> 1,100.00 </td>
              <td> 2,700.00 </td>
              <td align="right"> 4.00 </td>
              <td> 3,600.00 </td>
              <td> 4,400.00 </td>
              <td> 10,800.00 </td>
            </tr>
            <tr>
              <td> C.Arena Daily (2000/1000/10) </td>
              <td> 2,640.00 </td>
              <td> 3,360.00 </td>
              <td> 5,040.00 </td>
              <td align="right"> 30.00 </td>
              <td> 79,200.00 </td>
              <td> 100,800.00 </td>
              <td> 151,200.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 142,800.00 </td>
              <td> 171,200.00 </td>
              <td> 258,000.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Labyrinth Coins" />
        <p>
          Labyrinth Assumption - Sweep at Hero Route, You can potentially gain
          more by manualling the mode, +10% Coins if you have 13$ Monthly Sub.
          Average of 1 - 1.5 Adrianne per month.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Labyrinth Coins</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Labyrinth (Stage 1/5/9) </td>
              <td> 4,200.00 </td>
              <td> 5,000.00 </td>
              <td> 5,700.00 </td>
              <td align="right"> 15.00 </td>
              <td> 63,000.00 </td>
              <td> 75,000.00 </td>
              <td> 85,500.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 63,000.00 </td>
              <td> 75,000.00 </td>
              <td> 85,500.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Crystals - Monthly" />
        <p>
          Hall of Memories does not include Random Chest Rewards. Income
          excludes Hourly Idle Loot, Quick Loot and Town Part Time Jobs.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Crystals - Monthly</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Dual Gate (10/15) </td>
              <td> 200.00 </td>
              <td> 920.00 </td>
              <td> 1,320.00 </td>
              <td align="right"> 30.00 </td>
              <td> 6,000.00 </td>
              <td> 27,600.00 </td>
              <td> 39,600.00 </td>
            </tr>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td> 400.00 </td>
              <td> 1,080.00 </td>
              <td> 1,200.00 </td>
              <td align="right"> 4.00 </td>
              <td> 1,600.00 </td>
              <td> 4,320.00 </td>
              <td> 4,800.00 </td>
            </tr>
            <tr>
              <td> Event Stages First Clear Rewards </td>
              <td> - </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
              <td align="right"> 1.00 </td>
              <td> - </td>
              <td> 3,000.00 </td>
              <td> 3,000.00 </td>
            </tr>
            <tr>
              <td> Gachapon </td>
              <td> 1,500.00 </td>
              <td> 1,500.00 </td>
              <td> 1,500.00 </td>
              <td align="right"> 1.00 </td>
              <td> 1,500.00 </td>
              <td> 1,500.00 </td>
              <td> 1,500.00 </td>
            </tr>
            <tr>
              <td> Event Raid Clear </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td align="right"> 14.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
            </tr>
            <tr>
              <td> Event Raid Clear (Rerun) </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td align="right"> 14.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 14,140.00 </td>
              <td> 41,460.00 </td>
              <td> 53,940.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Crystals - Monthly (Hourly)" />
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Crystals - Monthly (Hourly)</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Dailies (2 Hour) </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 4.00 </td>
              <td align="right"> 30.00 </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
            </tr>
            <tr>
              <td> Weeklies (6 Hour) </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 4.00 </td>
              <td> 48.00 </td>
              <td> 48.00 </td>
              <td> 48.00 </td>
            </tr>
            <tr>
              <td> Soul Pass Free (2 Hour) </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 1.00 </td>
              <td> 10.00 </td>
              <td> 10.00 </td>
              <td> 10.00 </td>
            </tr>
            <tr>
              <td> Soul Pass Free (6 Hour) </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 1.00 </td>
              <td> 36.00 </td>
              <td> 36.00 </td>
              <td> 36.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions (18 Hour) </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 1.50 </td>
              <td> 27.00 </td>
              <td> 27.00 </td>
              <td> 27.00 </td>
            </tr>
            <tr>
              <td> Event Shop (2 Hours) </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
            </tr>
            <tr>
              <td> Season Pass Free (2 Hours) </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 1.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
            </tr>
            <tr>
              <td> Event Shop [Rerun] (2 Hours) </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 30.00 </td>
              <td align="right"> 1.00 </td>
              <td> 30.00 </td>
              <td> 30.00 </td>
              <td> 30.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 381.00 </td>
              <td> 381.00 </td>
              <td> 381.00 </td>
            </tr>
            <tr>
              <td> Monthly Sub (13$) (6 Hour) </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 1.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) (6 Hour) </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) (12 Hour) </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 1.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
            </tr>
            <tr>
              <td> Season pass (Paid - 22$) (6 Hours) </td>
              <td align="right"> 72.00 </td>
              <td align="right"> 72.00 </td>
              <td align="right"> 72.00 </td>
              <td align="right"> 1.00 </td>
              <td> 72.00 </td>
              <td> 72.00 </td>
              <td> 72.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 672.00 </td>
              <td> 672.00 </td>
              <td> 672.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 1,053.00 </td>
              <td> 1,053.00 </td>
              <td> 1,053.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Mana Dust" />
        <p>
          Hall of Memories does not include Random Chest Rewards. Income
          excludes Hourly Idle Loot, Quick Loot and Town Part Time Jobs.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Mana Dust</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td align="right"> 500.00 </td>
              <td> 2,180.00 </td>
              <td> 2,420.00 </td>
              <td align="right"> 4.00 </td>
              <td> 2,000.00 </td>
              <td> 8,720.00 </td>
              <td> 9,680.00 </td>
            </tr>
            <tr>
              <td> Gachapon </td>
              <td align="right"> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td align="right"> 1.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
            </tr>
            <tr>
              <td> Event Raid Clear </td>
              <td align="right"> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td align="right"> 14.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
            </tr>
            <tr>
              <td> Event Raid Clear (Rerun) </td>
              <td align="right"> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td align="right"> 14.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
              <td> 2,520.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 7,640.00 </td>
              <td> 14,360.00 </td>
              <td> 15,320.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Mana Dust - Monthly (Hourly)" />
        <p>
          Soul Pass Paid assumes, Gold or Dust chests were picked in favor of
          Dust.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Mana Dust - Monthly (Hourly)</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Dailies (2 Hour) </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 6.00 </td>
              <td align="right"> 30.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
            </tr>
            <tr>
              <td> Weeklies (6 Hour) </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 4.00 </td>
              <td> 48.00 </td>
              <td> 48.00 </td>
              <td> 48.00 </td>
            </tr>
            <tr>
              <td> Soul Pass Free (2 Hour) </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 1.00 </td>
              <td> 18.00 </td>
              <td> 18.00 </td>
              <td> 18.00 </td>
            </tr>
            <tr>
              <td> Soul Pass Free (6 Hour) </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 1.00 </td>
              <td> 36.00 </td>
              <td> 36.00 </td>
              <td> 36.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions (18 Hour) </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 1.50 </td>
              <td> 27.00 </td>
              <td> 27.00 </td>
              <td> 27.00 </td>
            </tr>
            <tr>
              <td> Labyrinth (Hero) </td>
              <td align="right"> 14.00 </td>
              <td align="right"> 14.00 </td>
              <td align="right"> 14.00 </td>
              <td align="right"> 15.00 </td>
              <td> 210.00 </td>
              <td> 210.00 </td>
              <td> 210.00 </td>
            </tr>
            <tr>
              <td> Event Shop (2 Hours) </td>
              <td align="right"> 100.00 </td>
              <td align="right"> 100.00 </td>
              <td align="right"> 100.00 </td>
              <td align="right"> 1.00 </td>
              <td> 100.00 </td>
              <td> 100.00 </td>
              <td> 100.00 </td>
            </tr>
            <tr>
              <td> Season Pass Free (2 Hours) </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 1.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
            </tr>
            <tr>
              <td> Event Shop [Rerun] (2 Hours) </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 729.00 </td>
              <td> 729.00 </td>
              <td> 729.00 </td>
            </tr>
            <tr>
              <td> Monthly Sub (13$) (6 Hour) </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 1.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) (6 Hour) </td>
              <td align="right"> 162.00 </td>
              <td align="right"> 162.00 </td>
              <td align="right"> 162.00 </td>
              <td align="right"> 1.00 </td>
              <td> 162.00 </td>
              <td> 162.00 </td>
              <td> 162.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) (12 Hour) </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 1.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
            </tr>
            <tr>
              <td> Season pass (Paid - 22$) (6 Hours) </td>
              <td align="right"> 24.00 </td>
              <td align="right"> 24.00 </td>
              <td align="right"> 24.00 </td>
              <td align="right"> 1.00 </td>
              <td> 24.00 </td>
              <td> 24.00 </td>
              <td> 24.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 726.00 </td>
              <td> 726.00 </td>
              <td> 726.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 1,455.00 </td>
              <td> 1,455.00 </td>
              <td> 1,455.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Gold" />
        <p>
          Hall of Memories does not include Random Chest Rewards. Income
          excludes Hourly Idle Loot, Quick Loot and Town Part Time Jobs.
        </p>

        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Gold</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Hall of Memories (1/6/10) </td>
              <td> 350.00 </td>
              <td> 1,610.00 </td>
              <td> 1,790.00 </td>
              <td align="right"> 4.00 </td>
              <td> 1,400.00 </td>
              <td> 6,440.00 </td>
              <td> 7,160.00 </td>
            </tr>
            <tr>
              <td> Labyrinth (Stage 1/5/9) </td>
              <td> 203.00 </td>
              <td> 886.00 </td>
              <td> 1,081.00 </td>
              <td align="right"> 15.00 </td>
              <td> 3,045.00 </td>
              <td> 13,290.00 </td>
              <td> 16,215.00 </td>
            </tr>
            <tr>
              <td> Event Stages Clear Rewards </td>
              <td> - </td>
              <td> 1,000.00 </td>
              <td> 1,000.00 </td>
              <td align="right"> 10.00 </td>
              <td> - </td>
              <td> 10,000.00 </td>
              <td> 10,000.00 </td>
            </tr>
            <tr>
              <td> Gachapon </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td align="right"> 1.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
              <td> 600.00 </td>
            </tr>
            <tr>
              <td> Event Raid Clear </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
              <td align="right"> 14.00 </td>
              <td> 1,680.00 </td>
              <td> 1,680.00 </td>
              <td> 1,680.00 </td>
            </tr>
            <tr>
              <td> Event Raid Clear (Rerun) </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
              <td> 120.00 </td>
              <td align="right"> 14.00 </td>
              <td> 1,680.00 </td>
              <td> 1,680.00 </td>
              <td> 1,680.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td> </td>
              <td> </td>
              <td> </td>
              <td align="right"></td>
              <td> 8,405.00 </td>
              <td> 33,690.00 </td>
              <td> 37,335.00 </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Gold" />
        <p>
          Soul Pass Paid assumes, Gold or Dust chests were picked in favor of
          Dust.
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th title="Field #1">Gold - Monthly (Hourly)</th>
              <th title="Field #2">Min</th>
              <th title="Field #3">Avg</th>
              <th title="Field #4">Max</th>
              <th title="Field #5">Freq M</th>
              <th title="Field #6">Min Monthly</th>
              <th title="Field #7">Avg Monthly</th>
              <th title="Field #8">Max Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td> Dailies (2 Hour) </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 3.00 </td>
              <td align="right"> 30.00 </td>
              <td> 90.00 </td>
              <td> 90.00 </td>
              <td> 90.00 </td>
            </tr>
            <tr>
              <td> Weeklies (6 Hour) </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 12.00 </td>
              <td align="right"> 4.00 </td>
              <td> 48.00 </td>
              <td> 48.00 </td>
              <td> 48.00 </td>
            </tr>
            <tr>
              <td> Soul Pass Free (2 Hour) </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 10.00 </td>
              <td align="right"> 1.00 </td>
              <td> 10.00 </td>
              <td> 10.00 </td>
              <td> 10.00 </td>
            </tr>
            <tr>
              <td> Soul Pass Free (6 Hour) </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 36.00 </td>
              <td align="right"> 1.00 </td>
              <td> 36.00 </td>
              <td> 36.00 </td>
              <td> 36.00 </td>
            </tr>
            <tr>
              <td> Guild Raid Missions (18 Hour) </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 1.50 </td>
              <td> 27.00 </td>
              <td> 27.00 </td>
              <td> 27.00 </td>
            </tr>
            <tr>
              <td> Labyrinth (Hero) </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 18.00 </td>
              <td align="right"> 15.00 </td>
              <td> 270.00 </td>
              <td> 270.00 </td>
              <td> 270.00 </td>
            </tr>
            <tr>
              <td> Event Shop (2 Hours) </td>
              <td align="right"> 100.00 </td>
              <td align="right"> 100.00 </td>
              <td align="right"> 100.00 </td>
              <td align="right"> 1.00 </td>
              <td> 100.00 </td>
              <td> 100.00 </td>
              <td> 100.00 </td>
            </tr>
            <tr>
              <td> Season Pass Free (2 Hours) </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 50.00 </td>
              <td align="right"> 1.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
              <td> 50.00 </td>
            </tr>
            <tr>
              <td> Event Shop [Rerun] (2 Hours) </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 60.00 </td>
              <td align="right"> 1.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
              <td> 60.00 </td>
            </tr>
            <tr>
              <td> F2P Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 691.00 </td>
              <td> 691.00 </td>
              <td> 691.00 </td>
            </tr>
            <tr>
              <td> Monthly Sub (13$) (6 Hour) </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 180.00 </td>
              <td align="right"> 1.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
              <td> 180.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) (6 Hour) </td>
              <td align="right"> 90.00 </td>
              <td align="right"> 90.00 </td>
              <td align="right"> 90.00 </td>
              <td align="right"> 1.00 </td>
              <td> 90.00 </td>
              <td> 90.00 </td>
              <td> 90.00 </td>
            </tr>
            <tr>
              <td> Soul Pass (Paid - 13$) (12 Hour) </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 360.00 </td>
              <td align="right"> 1.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
              <td> 360.00 </td>
            </tr>
            <tr>
              <td> Season pass (Paid - 22$) (6 Hours) </td>
              <td align="right"> 72.00 </td>
              <td align="right"> 72.00 </td>
              <td align="right"> 72.00 </td>
              <td align="right"> 1.00 </td>
              <td> 72.00 </td>
              <td> 72.00 </td>
              <td> 72.00 </td>
            </tr>
            <tr>
              <td> Small Spender Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 702.00 </td>
              <td> 702.00 </td>
              <td> 702.00 </td>
            </tr>
            <tr>
              <td> Total Income </td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td align="right"></td>
              <td> 1,393.00 </td>
              <td> 1,393.00 </td>
              <td> 1,393.00 </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default EsGuidesMonthlyPage;

export const Head: React.FC = () => (
  <Seo
    title="Monthly resource income (F2P) | Eversoul | Prydwen Institute"
    description="Learn how much tickets, Everstone and other resources you can earn every month as a F2P player."
    game="eversoul"
  />
);
